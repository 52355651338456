/**
 * Created by bioz on 4/12/2017.
 */

export const APIServer = 'https://api.baba.cublick.com/v1';
//export const APIServer = 'http://localhost:3000/v1';

export const StatusEnum = [
    { id: 'ACTIVATED', name: 'activate' },
    { id: 'DEACTIVATED', name: 'deactivate' },
    { id: 'DELETED', name: 'delete' },
    { id: 'WAITING_APPROVE', name: 'waiting' },
];

export const UserRightEnum = [
    { id: 'ANONYMOUS', name: 'anonymous' },
    { id: 'END_USER', name: 'user' },
    { id: 'MODERATOR', name: 'moderator' },
    { id: 'ADMIN', name: 'admin' },
    { id: 'SUPER_ADMIN', name: 'super admin' },
];

export const ResolutionEnum = [
    { id: '{"width":3840,"height":2160}', name: '3840 x 2160 (UHD)' },
    { id: '{"width":2560,"height":1440}', name: '2560 x 1440 (QHD)' },
    { id: '{"width":1920,"height":1080}', name: '1920 x 1080 (FHD)' },
    { id: '{"width":1280,"height":720}', name: '1280 x 720 (HD)' },
    { id: '{"width":960,"height":540}', name: '960 x 540 (qHD)' },
    { id: '{"width":800,"height":450}', name: '800 x 450' },
    { id: '{"width":640,"height":360}', name: '640 x 360' },
    { id: '{"width":480,"height":272}', name: '480 x 272' },

];

export const RotateEnum = [
    { id: 0, name: '0' },
    { id: 90, name: '90' },
    { id: 180, name: '180' },
    { id: 270, name: '270' },
];
