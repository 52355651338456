import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';

import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import { DeviceIcon } from './devices';
import { AccountIcon } from './accounts';
import { FittingMediaIcon } from './fittingMedia';

import { UserRightEnum } from './biUtils/constant';
import { CheckExist } from './biUtils/BiField';

const myMenuItems = [
    {
        name: 'auth/accounts',
        label: 'account',
        icon: <AccountIcon />,
        right: 'ADMIN',
    },
    {
        name: 'auth/devices',
        label: 'device',
        icon: <DeviceIcon />,
        right: 'END_USER',
    },
    {
        name: 'auth/fitting_medias',
        label: 'fitting',
        icon: <FittingMediaIcon />,
        right: 'END_USER',
    },
];

class Menu extends Component {
    //const Menu = ({ onMenuClick, translate, logout }) => (
    constructor(props) {
        super(props);
        const accessUserRight = localStorage.getItem('userRight');
        const userRightIdx = CheckExist(UserRightEnum, accessUserRight);
        const lowerUserRightList = UserRightEnum.slice(0, userRightIdx);
        let items = myMenuItems.slice(0);

        for (let j = 0; j < items.length; j++) {
            if (CheckExist(lowerUserRightList, items[j].right) === 0) {
                items.splice(j, 1);
                j--;
            }
        }
        this.state = { displayMenu: items };
    }

    render() {
        return (
            <div>
                <DashboardMenuItem onClick={this.props.onMenuClick} />
                {this.state.displayMenu.map(item => (
                    <MenuItemLink
                        key={item.name}
                        to={`/${item.name}`}
                        primaryText={this.props.translate(
                            `resources.${item.label}.name`,
                            {
                                smart_count: 2,
                            }
                        )}
                        leftIcon={item.icon}
                        onClick={this.props.onMenuClick}
                    />
                ))}
                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={this.props.translate(
                                'pos.configuration'
                            )}
                            leftIcon={<SettingsIcon />}
                            onClick={this.props.onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive xsmall={this.props.logout} medium={null} />
            </div>
        );
    }
}

const enhance = compose(
    withRouter,
    connect(
        state => ({
            theme: state.theme,
            locale: state.i18n.locale,
        }),
        {}
    ),
    translate
);

export default enhance(Menu);
