import React, { Component, Fragment } from 'react';
import { Responsive, Title } from 'react-admin';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';
import dataProviderFactory from '../dataProvider';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
            dataProvider => {
                dataProvider('GET_INFO', 'auth/accounts/statistic', null)
                    .then(response => response.data)
                    .then(data => {
                        this.setState({ activatedAccount: data.activated });
                        this.setState({ totalAccount: data.total });
                    });
                dataProvider(
                    'GET_INFO', 'auth/devices/statistic', null )
                    .then(response => response.data)
                    .then(data => {
                        this.setState({ activatedDevice: data.activated });
                        this.setState({ totalDevice: data.total });
                    });
                dataProvider('GET_INFO', 'auth/fitting_medias/statistic', null)
                    .then(response => response.data)
                    .then(data => {
                        this.setState({ activatedMedia: data.activated });
                        this.setState({ totalMedia: data.total });
                    });
            }
        );
    }

    render() {
        const {
            activatedAccount = 0,
            totalAccount = 0,
            activatedDevice = 0,
            totalDevice = 0,
            activatedMedia = 0,
            totalMedia = 0,
        } = this.state;
        return (
            <Fragment>
                <Title title="Dashboad" />
                <Responsive
                    xsmall={
                        <div>
                            <div style={styles.flexColumn}>
                                <div style={{ marginBottom: '1em' }}>
                                    <Welcome />
                                </div>
                                <div style={styles.flex}>
                                    <MonthlyRevenue
                                        value={
                                            ' ' +
                                            activatedAccount +
                                            ' / ' +
                                            totalAccount
                                        }
                                    />
                                    <NbNewOrders
                                        value={
                                            ' ' +
                                            activatedDevice +
                                            ' / ' +
                                            totalDevice
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    small={
                        <div style={styles.flexColumn}>
                            <div style={styles.singleCol}>
                                <Welcome />
                            </div>
                            <div style={styles.flex}>
                                <MonthlyRevenue
                                    value={
                                        ' ' +
                                        activatedAccount +
                                        ' / ' +
                                        totalAccount
                                    }
                                />
                                <NbNewOrders
                                    value={
                                        ' ' +
                                        activatedDevice +
                                        ' / ' +
                                        totalDevice
                                    }
                                />
                            </div>
                        </div>
                    }
                    medium={
                        <div>
                            <div style={{ marginBottom: '1em' }}>
                                <Welcome />
                            </div>
                            <div style={styles.flex}>
                                <div style={styles.leftCol}>
                                    <div style={styles.flex}>
                                        <MonthlyRevenue
                                            value={
                                                ' ' +
                                                activatedAccount +
                                                ' / ' +
                                                totalAccount
                                            }
                                        />
                                        <NbNewOrders
                                            value={
                                                ' ' +
                                                activatedDevice +
                                                ' / ' +
                                                totalDevice
                                            }
                                        />
                                    </div>
                                </div>
                                <div style={styles.rightCol}>
                                    <div style={styles.flex}>
                                        <PendingReviews
                                            value={
                                                ' ' +
                                                activatedMedia +
                                                ' / ' +
                                                totalMedia
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

export default Dashboard;
