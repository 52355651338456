var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions';
var initialState = {
  isLoggedIn: false
};
export default (function (previousState, action) {
  if (previousState === void 0) {
    previousState = initialState;
  }

  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return __assign({}, previousState, {
        isLoggedIn: true
      });

    case USER_LOGOUT:
      return __assign({}, previousState, {
        isLoggedIn: false
      });
  }

  return previousState;
});
export var isLoggedIn = function isLoggedIn(state) {
  return state.isLoggedIn;
};