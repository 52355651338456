var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { INITIALIZE_FORM, RESET_FORM } from '../../actions/formActions';
import set from 'lodash/set';
var initialState = {};
export default (function (previousState, _a) {
  if (previousState === void 0) {
    previousState = initialState;
  }

  var type = _a.type,
      payload = _a.payload;

  if (type === RESET_FORM) {
    return initialState;
  }

  if (type !== INITIALIZE_FORM) {
    return previousState;
  }

  return Object.keys(payload).reduce(function (acc, key) {
    // Ensure we correctly set default values for path with dot notation
    set(acc, key, payload[key]);
    return acc;
  }, __assign({}, previousState));
});