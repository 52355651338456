import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_CHECK,
    AUTH_ERROR,
    AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { APIServer } from './biUtils/constant';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        ///*
        const { username, password } = params;
        const request = new Request(APIServer + '/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, displayName, userRight }) => {
                localStorage.setItem('username', displayName);
                localStorage.setItem('token', token);
                localStorage.setItem('userRight', userRight);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('userRight');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            localStorage.removeItem('userRight');
            return Promise.reject();
        } else {
            return Promise.resolve();
        }
    }
    if (type === AUTH_CHECK) {
        const bIsOK =
            localStorage.getItem('token') &&
            localStorage.getItem('username') &&
            localStorage.getItem('userRight');
        if (bIsOK) {
            return Promise.resolve();
        }
        return Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('userRight');
        return role
            ? Promise.resolve(role)
            : Promise.reject({ redirectTo: '/no-access' });
    }
    return Promise.reject({ redirectTo: '/no-access' });
};
