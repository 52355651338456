var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';
export var BooleanField = function BooleanField(_a) {
  var className = _a.className,
      source = _a.source,
      _b = _a.record,
      record = _b === void 0 ? {} : _b,
      rest = __rest(_a, ["className", "source", "record"]);

  if (get(record, source) === false) {
    return React.createElement(Typography, __assign({
      component: "span",
      body1: "body1",
      className: className
    }, sanitizeRestProps(rest)), React.createElement(FalseIcon, null));
  }

  if (get(record, source) === true) {
    return React.createElement(Typography, __assign({
      component: "span",
      body1: "body1",
      className: className
    }, sanitizeRestProps(rest)), React.createElement(TrueIcon, null));
  }

  return React.createElement(Typography, __assign({
    component: "span",
    body1: "body1",
    className: className
  }, sanitizeRestProps(rest)));
};
BooleanField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired
};
var PureBooleanField = pure(BooleanField);
PureBooleanField.defaultProps = {
  addLabel: true
};
export default PureBooleanField;