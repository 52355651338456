var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import defaultTheme from '../defaultTheme';
import Notification from '../layout/Notification';
import DefaultLoginForm from './LoginForm';

var styles = function styles(theme) {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'url(https://source.unsplash.com/random/1600x900)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    card: {
      minWidth: 300,
      marginTop: '6em'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    }
  };
};

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var classes = _a.classes,
      className = _a.className,
      location = _a.location,
      title = _a.title,
      array = _a.array,
      theme = _a.theme,
      staticContext = _a.staticContext,
      rest = __rest(_a, ["classes", "className", "location", "title", "array", "theme", "staticContext"]);

  return rest;
};
/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */


var Login = function Login(_a) {
  var classes = _a.classes,
      className = _a.className,
      loginForm = _a.loginForm,
      rest = __rest(_a, ["classes", "className", "loginForm"]);

  return React.createElement("div", __assign({
    className: classnames(classes.main, className)
  }, sanitizeRestProps(rest)), React.createElement(Card, {
    className: classes.card
  }, React.createElement("div", {
    className: classes.avatar
  }, React.createElement(Avatar, {
    className: classes.icon
  }, React.createElement(LockIcon, null))), loginForm), React.createElement(Notification, null));
};

Login.propTypes = {
  className: PropTypes.string,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  previousRoute: PropTypes.string,
  loginForm: PropTypes.element
};
Login.defaultProps = {
  theme: defaultTheme,
  loginForm: React.createElement(DefaultLoginForm, null)
};
export default withStyles(styles)(Login);