var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import createHistory from 'history/createHashHistory';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import withContext from 'recompose/withContext';
import createAdminStore from './createAdminStore';
import TranslationProvider from './i18n/TranslationProvider';
import CoreAdminRouter from './CoreAdminRouter';
var componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string]);

var CoreAdmin =
/** @class */
function (_super) {
  __extends(CoreAdmin, _super);

  function CoreAdmin(props, context) {
    var _this = _super.call(this, props, context) || this;

    _this.reduxIsAlreadyInitialized = false;
    _this.history = null;

    if (context.store) {
      _this.reduxIsAlreadyInitialized = true;

      if (!props.history) {
        throw new Error("Missing history prop.\nWhen integrating react-admin inside an existing redux Provider, you must provide the same 'history' prop to the <Admin> as the one used to bootstrap your routerMiddleware.\nReact-admin uses this history for its own ConnectedRouter.");
      }

      _this.history = props.history;
    } else {
      if (!props.dataProvider) {
        throw new Error("Missing dataProvider prop.\nReact-admin requires a valid dataProvider function to work.");
      }

      _this.history = props.history || createHistory();
    }

    return _this;
  }

  CoreAdmin.prototype.renderCore = function () {
    var _a = this.props,
        appLayout = _a.appLayout,
        authProvider = _a.authProvider,
        children = _a.children,
        _b = _a.customRoutes,
        customRoutes = _b === void 0 ? [] : _b,
        dashboard = _a.dashboard,
        menu = _a.menu,
        // deprecated, use a custom layout instead
    catchAll = _a.catchAll,
        theme = _a.theme,
        _c = _a.title,
        title = _c === void 0 ? 'React Admin' : _c,
        loading = _a.loading,
        loginPage = _a.loginPage,
        logoutButton = _a.logoutButton;
    var logout = authProvider ? createElement(logoutButton) : null;
    return React.createElement(TranslationProvider, null, React.createElement(ConnectedRouter, {
      history: this.history
    }, React.createElement(Switch, null, React.createElement(Route, {
      exact: true,
      path: "/login",
      render: function render(props) {
        return createElement(loginPage, __assign({}, props, {
          title: title
        }));
      }
    }), React.createElement(Route, {
      path: "/",
      render: function render(props) {
        return React.createElement(CoreAdminRouter, __assign({
          appLayout: appLayout,
          catchAll: catchAll,
          customRoutes: customRoutes,
          dashboard: dashboard,
          loading: loading,
          loginPage: loginPage,
          logout: logout,
          menu: menu,
          theme: theme,
          title: title
        }, props), children);
      }
    }))));
  };

  CoreAdmin.prototype.render = function () {
    return this.reduxIsAlreadyInitialized ? this.renderCore() : React.createElement(Provider, {
      store: createAdminStore(__assign({}, this.props, {
        history: this.history
      }))
    }, this.renderCore());
  };

  CoreAdmin.propTypes = {
    appLayout: componentPropType,
    authProvider: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    catchAll: componentPropType,
    customSagas: PropTypes.array,
    customReducers: PropTypes.object,
    customRoutes: PropTypes.array,
    dashboard: componentPropType,
    dataProvider: PropTypes.func,
    history: PropTypes.object,
    i18nProvider: PropTypes.func,
    initialState: PropTypes.object,
    loading: componentPropType,
    locale: PropTypes.string,
    loginPage: componentPropType,
    logoutButton: componentPropType,
    menu: componentPropType,
    theme: PropTypes.object,
    title: PropTypes.node
  };
  CoreAdmin.contextTypes = {
    store: PropTypes.object
  };
  return CoreAdmin;
}(React.Component);

export default withContext({
  authProvider: PropTypes.func
}, function (_a) {
  var authProvider = _a.authProvider;
  return {
    authProvider: authProvider
  };
})(CoreAdmin);