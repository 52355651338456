import { GET_LIST, GET_ONE, CREATE, UPDATE, UPDATE_MANY, DELETE, DELETE_MANY, GET_MANY, GET_MANY_REFERENCE } from '../dataFetchActions';
export var CRUD_GET_LIST = 'RA/CRUD_GET_LIST';
export var CRUD_GET_LIST_LOADING = 'RA/CRUD_GET_LIST_LOADING';
export var CRUD_GET_LIST_FAILURE = 'RA/CRUD_GET_LIST_FAILURE';
export var CRUD_GET_LIST_SUCCESS = 'RA/CRUD_GET_LIST_SUCCESS';
export var crudGetList = function crudGetList(resource, pagination, sort, filter) {
  return {
    type: CRUD_GET_LIST,
    payload: {
      pagination: pagination,
      sort: sort,
      filter: filter
    },
    meta: {
      resource: resource,
      fetch: GET_LIST,
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_GET_ALL = 'RA/CRUD_GET_ALL';
export var CRUD_GET_ALL_LOADING = 'RA/CRUD_GET_ALL_LOADING';
export var CRUD_GET_ALL_FAILURE = 'RA/CRUD_GET_ALL_FAILURE';
export var CRUD_GET_ALL_SUCCESS = 'RA/CRUD_GET_ALL_SUCCESS';
export var crudGetAll = function crudGetAll(resource, sort, filter, maxResults, callback) {
  return {
    type: CRUD_GET_ALL,
    payload: {
      sort: sort,
      filter: filter,
      pagination: {
        page: 1,
        perPage: maxResults
      }
    },
    meta: {
      resource: resource,
      fetch: GET_LIST,
      onSuccess: {
        callback: callback
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_GET_ONE = 'RA/CRUD_GET_ONE';
export var CRUD_GET_ONE_LOADING = 'RA/CRUD_GET_ONE_LOADING';
export var CRUD_GET_ONE_FAILURE = 'RA/CRUD_GET_ONE_FAILURE';
export var CRUD_GET_ONE_SUCCESS = 'RA/CRUD_GET_ONE_SUCCESS';
export var crudGetOne = function crudGetOne(resource, id, basePath, refresh) {
  if (refresh === void 0) {
    refresh = true;
  }

  return {
    type: CRUD_GET_ONE,
    payload: {
      id: id
    },
    meta: {
      resource: resource,
      fetch: GET_ONE,
      basePath: basePath,
      onFailure: {
        notification: {
          body: 'ra.notification.item_doesnt_exist',
          level: 'warning'
        },
        redirectTo: 'list',
        refresh: refresh
      }
    }
  };
};
export var CRUD_CREATE = 'RA/CRUD_CREATE';
export var CRUD_CREATE_LOADING = 'RA/CRUD_CREATE_LOADING';
export var CRUD_CREATE_FAILURE = 'RA/CRUD_CREATE_FAILURE';
export var CRUD_CREATE_SUCCESS = 'RA/CRUD_CREATE_SUCCESS';
export var crudCreate = function crudCreate(resource, data, basePath, redirectTo) {
  if (redirectTo === void 0) {
    redirectTo = 'edit';
  }

  return {
    type: CRUD_CREATE,
    payload: {
      data: data
    },
    meta: {
      resource: resource,
      fetch: CREATE,
      onSuccess: {
        notification: {
          body: 'ra.notification.created',
          level: 'info',
          messageArgs: {
            smart_count: 1
          }
        },
        redirectTo: redirectTo,
        basePath: basePath
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_UPDATE = 'RA/CRUD_UPDATE';
export var CRUD_UPDATE_LOADING = 'RA/CRUD_UPDATE_LOADING';
export var CRUD_UPDATE_FAILURE = 'RA/CRUD_UPDATE_FAILURE';
export var CRUD_UPDATE_SUCCESS = 'RA/CRUD_UPDATE_SUCCESS';
export var CRUD_UPDATE_OPTIMISTIC = 'RA/CRUD_UPDATE_OPTIMISTIC';
export var crudUpdate = function crudUpdate(resource, id, data, previousData, basePath, redirectTo) {
  if (redirectTo === void 0) {
    redirectTo = 'show';
  }

  return {
    type: CRUD_UPDATE,
    payload: {
      id: id,
      data: data,
      previousData: previousData
    },
    meta: {
      resource: resource,
      fetch: UPDATE,
      onSuccess: {
        notification: {
          body: 'ra.notification.updated',
          level: 'info',
          messageArgs: {
            smart_count: 1
          }
        },
        redirectTo: redirectTo,
        basePath: basePath
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_UPDATE_MANY = 'RA/CRUD_UPDATE_MANY';
export var CRUD_UPDATE_MANY_LOADING = 'RA/CRUD_UPDATE_MANY_LOADING';
export var CRUD_UPDATE_MANY_FAILURE = 'RA/CRUD_UPDATE_MANY_FAILURE';
export var CRUD_UPDATE_MANY_SUCCESS = 'RA/CRUD_UPDATE_MANY_SUCCESS';
export var CRUD_UPDATE_MANY_OPTIMISTIC = 'RA/CRUD_UPDATE_MANY_OPTIMISTIC';
export var crudUpdateMany = function crudUpdateMany(resource, ids, data, basePath, refresh) {
  if (refresh === void 0) {
    refresh = true;
  }

  return {
    type: CRUD_UPDATE_MANY,
    payload: {
      ids: ids,
      data: data
    },
    meta: {
      resource: resource,
      fetch: UPDATE_MANY,
      cancelPrevious: false,
      onSuccess: {
        notification: {
          body: 'ra.notification.updated',
          level: 'info',
          messageArgs: {
            smart_count: ids.length
          }
        },
        basePath: basePath,
        refresh: refresh,
        unselectAll: true
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_DELETE = 'RA/CRUD_DELETE';
export var CRUD_DELETE_LOADING = 'RA/CRUD_DELETE_LOADING';
export var CRUD_DELETE_FAILURE = 'RA/CRUD_DELETE_FAILURE';
export var CRUD_DELETE_SUCCESS = 'RA/CRUD_DELETE_SUCCESS';
export var CRUD_DELETE_OPTIMISTIC = 'RA/CRUD_DELETE_OPTIMISTIC';
export var crudDelete = function crudDelete(resource, id, previousData, basePath, redirectTo) {
  if (redirectTo === void 0) {
    redirectTo = 'list';
  }

  return {
    type: CRUD_DELETE,
    payload: {
      id: id,
      previousData: previousData
    },
    meta: {
      resource: resource,
      fetch: DELETE,
      onSuccess: {
        notification: {
          body: 'ra.notification.deleted',
          level: 'info',
          messageArgs: {
            smart_count: 1
          }
        },
        redirectTo: redirectTo,
        basePath: basePath
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_DELETE_MANY = 'RA/CRUD_DELETE_MANY';
export var CRUD_DELETE_MANY_LOADING = 'RA/CRUD_DELETE_MANY_LOADING';
export var CRUD_DELETE_MANY_FAILURE = 'RA/CRUD_DELETE_MANY_FAILURE';
export var CRUD_DELETE_MANY_SUCCESS = 'RA/CRUD_DELETE_MANY_SUCCESS';
export var CRUD_DELETE_MANY_OPTIMISTIC = 'RA/CRUD_DELETE_MANY_OPTIMISTIC';
export var crudDeleteMany = function crudDeleteMany(resource, ids, basePath, refresh) {
  if (refresh === void 0) {
    refresh = true;
  }

  return {
    type: CRUD_DELETE_MANY,
    payload: {
      ids: ids
    },
    meta: {
      resource: resource,
      fetch: DELETE_MANY,
      onSuccess: {
        notification: {
          body: 'ra.notification.deleted',
          level: 'info',
          messageArgs: {
            smart_count: ids.length
          }
        },
        basePath: basePath,
        refresh: refresh,
        unselectAll: true
      },
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_GET_MANY = 'RA/CRUD_GET_MANY';
export var CRUD_GET_MANY_LOADING = 'RA/CRUD_GET_MANY_LOADING';
export var CRUD_GET_MANY_FAILURE = 'RA/CRUD_GET_MANY_FAILURE';
export var CRUD_GET_MANY_SUCCESS = 'RA/CRUD_GET_MANY_SUCCESS'; // Reference related actions

export var crudGetMany = function crudGetMany(resource, ids) {
  return {
    type: CRUD_GET_MANY,
    payload: {
      ids: ids
    },
    meta: {
      resource: resource,
      fetch: GET_MANY,
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_GET_MATCHING = 'RA/CRUD_GET_MATCHING';
export var CRUD_GET_MATCHING_LOADING = 'RA/CRUD_GET_MATCHING_LOADING';
export var CRUD_GET_MATCHING_FAILURE = 'RA/CRUD_GET_MATCHING_FAILURE';
export var CRUD_GET_MATCHING_SUCCESS = 'RA/CRUD_GET_MATCHING_SUCCESS';
export var crudGetMatching = function crudGetMatching(reference, relatedTo, pagination, sort, filter) {
  return {
    type: CRUD_GET_MATCHING,
    payload: {
      pagination: pagination,
      sort: sort,
      filter: filter
    },
    meta: {
      resource: reference,
      relatedTo: relatedTo,
      fetch: GET_LIST,
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};
export var CRUD_GET_MANY_REFERENCE = 'RA/CRUD_GET_MANY_REFERENCE';
export var CRUD_GET_MANY_REFERENCE_LOADING = 'RA/CRUD_GET_MANY_REFERENCE_LOADING';
export var CRUD_GET_MANY_REFERENCE_FAILURE = 'RA/CRUD_GET_MANY_REFERENCE_FAILURE';
export var CRUD_GET_MANY_REFERENCE_SUCCESS = 'RA/CRUD_GET_MANY_REFERENCE_SUCCESS';
export var crudGetManyReference = function crudGetManyReference(reference, target, id, relatedTo, pagination, sort, filter, source) {
  return {
    type: CRUD_GET_MANY_REFERENCE,
    payload: {
      target: target,
      id: id,
      pagination: pagination,
      sort: sort,
      filter: filter,
      source: source
    },
    meta: {
      resource: reference,
      relatedTo: relatedTo,
      fetch: GET_MANY_REFERENCE,
      onFailure: {
        notification: {
          body: 'ra.notification.http_error',
          level: 'warning'
        }
      }
    }
  };
};