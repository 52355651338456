var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeForm as initializeFormAction } from '../actions/formActions';

var DefaultValue =
/** @class */
function (_super) {
  __extends(DefaultValue, _super);

  function DefaultValue() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  DefaultValue.prototype.componentDidMount = function () {
    var _a;

    var _b = this.props,
        defaultValue = _b.defaultValue,
        input = _b.input,
        initializeForm = _b.initializeForm,
        source = _b.source;

    if (typeof defaultValue === 'undefined' || input) {
      return;
    }

    initializeForm((_a = {}, _a[source] = typeof defaultValue === 'function' ? defaultValue() : defaultValue, _a));
  };

  DefaultValue.prototype.componentDidUpdate = function (prevProps) {
    var _a;

    var _b = this.props,
        defaultValue = _b.defaultValue,
        input = _b.input,
        initializeForm = _b.initializeForm,
        source = _b.source;

    if (typeof defaultValue === 'undefined' || input) {
      return;
    }

    if (defaultValue !== prevProps.defaultValue) {
      initializeForm((_a = {}, _a[source] = typeof defaultValue === 'function' ? defaultValue() : defaultValue, _a));
    }
  };

  DefaultValue.prototype.render = function () {
    var _a = this.props,
        initializeForm = _a.initializeForm,
        decoratedComponent = _a.decoratedComponent,
        props = __rest(_a, ["initializeForm", "decoratedComponent"]);

    return createElement(decoratedComponent, props);
  };

  DefaultValue.propTypes = {
    decoratedComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    defaultValue: PropTypes.any,
    initializeForm: PropTypes.func.isRequired,
    input: PropTypes.object,
    source: PropTypes.string,
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
  };
  return DefaultValue;
}(Component);

export { DefaultValue };
export default (function (DecoratedComponent) {
  return connect(function () {
    return {
      decoratedComponent: DecoratedComponent
    };
  }, {
    initializeForm: initializeFormAction
  })(DefaultValue);
});