var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import React, { Component } from 'react';
import pure from 'recompose/pure';
import Button from '@material-ui/core/Button';
import TablePaginationActions from '@material-ui/core/TablePaginationActions';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import compose from 'recompose/compose';
import { translate } from 'ra-core';

var styles = function styles(theme) {
  return {
    actions: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: 20
    },
    hellip: {
      padding: '1.2em'
    }
  };
};

var PaginationActions =
/** @class */
function (_super) {
  __extends(PaginationActions, _super);

  function PaginationActions() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.getNbPages = function () {
      return Math.ceil(_this.props.count / _this.props.rowsPerPage) || 1;
    };

    _this.prevPage = function (event) {
      if (_this.props.page === 0) {
        throw new Error(_this.props.translate('ra.navigation.page_out_from_begin'));
      }

      _this.props.onChangePage(event, _this.props.page - 1);
    };

    _this.nextPage = function (event) {
      if (_this.props.page > _this.getNbPages() - 1) {
        throw new Error(_this.props.translate('ra.navigation.page_out_from_end'));
      }

      _this.props.onChangePage(event, _this.props.page + 1);
    };

    _this.gotoPage = function (event) {
      var page = parseInt(event.currentTarget.dataset.page, 10);

      if (page < 0 || page > _this.getNbPages() - 1) {
        throw new Error(_this.props.translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1
        }));
      }

      _this.props.onChangePage(event, page);
    };

    return _this;
  }
  /**
   * Warning: material-ui's page is 0-based
   */


  PaginationActions.prototype.range = function () {
    var _a = this.props,
        page = _a.page,
        rowsPerPage = _a.rowsPerPage,
        count = _a.count;
    var nbPages = Math.ceil(count / rowsPerPage) || 1;

    if (isNaN(page) || nbPages === 1) {
      return [];
    }

    var input = []; // display page links around the current page

    if (page > 1) {
      input.push(1);
    }

    if (page === 3) {
      input.push(2);
    }

    if (page > 3) {
      input.push('.');
    }

    if (page > 0) {
      input.push(page);
    }

    input.push(page + 1);

    if (page < nbPages - 1) {
      input.push(page + 2);
    }

    if (page === nbPages - 4) {
      input.push(nbPages - 1);
    }

    if (page < nbPages - 4) {
      input.push('.');
    }

    if (page < nbPages - 2) {
      input.push(nbPages);
    }

    return input;
  };

  PaginationActions.prototype.renderPageNums = function () {
    var _this = this;

    var _a = this.props.classes,
        classes = _a === void 0 ? {} : _a;
    return this.range().map(function (pageNum, index) {
      return pageNum === '.' ? React.createElement("span", {
        key: "hyphen_" + index,
        className: classes.hellip
      }, "\u2026") : React.createElement(Button, {
        className: "page-number",
        color: pageNum === _this.props.page + 1 ? 'default' : 'primary',
        key: pageNum,
        "data-page": pageNum - 1,
        onClick: _this.gotoPage,
        size: "small"
      }, pageNum);
    });
  };

  PaginationActions.prototype.render = function () {
    var _a = this.props,
        _b = _a.classes,
        classes = _b === void 0 ? {} : _b,
        page = _a.page,
        translate = _a.translate;
    var nbPages = this.getNbPages();
    if (nbPages === 1) return React.createElement("div", {
      className: classes.actions
    });
    return React.createElement("div", {
      className: classes.actions
    }, page > 0 && React.createElement(Button, {
      color: "primary",
      key: "prev",
      onClick: this.prevPage,
      className: "previous-page",
      size: "small"
    }, React.createElement(ChevronLeft, null), translate('ra.navigation.prev')), this.renderPageNums(), page !== nbPages - 1 && React.createElement(Button, {
      color: "primary",
      key: "next",
      onClick: this.nextPage,
      className: "next-page",
      size: "small"
    }, translate('ra.navigation.next'), React.createElement(ChevronRight, null)));
  };

  return PaginationActions;
}(Component);

export { PaginationActions };
PaginationActions.propTypes = TablePaginationActions.propTypes;
var enhance = compose(pure, translate, withStyles(styles));
export default enhance(PaginationActions);