var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import React, { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import WithPermissions from './auth/WithPermissions';
import { registerResource, unregisterResource } from './actions';
var componentPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.string]);

var Resource =
/** @class */
function (_super) {
  __extends(Resource, _super);

  function Resource() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  Resource.prototype.componentWillMount = function () {
    var _a = this.props,
        context = _a.context,
        name = _a.name,
        list = _a.list,
        create = _a.create,
        edit = _a.edit,
        show = _a.show,
        options = _a.options,
        icon = _a.icon,
        registerResource = _a.registerResource;

    if (context === 'registration') {
      var resource = {
        name: name,
        options: options,
        hasList: !!list,
        hasEdit: !!edit,
        hasShow: !!show,
        hasCreate: !!create,
        icon: icon
      };
      registerResource(resource);
    }
  };

  Resource.prototype.componentWillUnmount = function () {
    var _a = this.props,
        context = _a.context,
        name = _a.name,
        unregisterResource = _a.unregisterResource;

    if (context === 'registration') {
      unregisterResource(name);
    }
  };

  Resource.prototype.render = function () {
    var _a = this.props,
        match = _a.match,
        context = _a.context,
        name = _a.name,
        list = _a.list,
        create = _a.create,
        edit = _a.edit,
        show = _a.show,
        options = _a.options;

    if (context === 'registration') {
      return null;
    }

    var resource = {
      resource: name,
      options: options,
      hasList: !!list,
      hasEdit: !!edit,
      hasShow: !!show,
      hasCreate: !!create
    };
    var basePath = match.url;
    return React.createElement(Switch, null, create && React.createElement(Route, {
      path: match.url + "/create",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          render: function render(props) {
            return createElement(create, __assign({
              basePath: basePath
            }, props));
          }
        }, routeProps, resource));
      }
    }), show && React.createElement(Route, {
      path: match.url + "/:id/show",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          render: function render(props) {
            return createElement(show, __assign({
              basePath: basePath,
              id: decodeURIComponent(props.match.params.id)
            }, props));
          }
        }, routeProps, resource));
      }
    }), edit && React.createElement(Route, {
      path: match.url + "/:id",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          render: function render(props) {
            return createElement(edit, __assign({
              basePath: basePath,
              id: decodeURIComponent(props.match.params.id)
            }, props));
          }
        }, routeProps, resource));
      }
    }), list && React.createElement(Route, {
      path: "" + match.url,
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          render: function render(props) {
            return createElement(list, __assign({
              basePath: basePath
            }, props));
          }
        }, routeProps, resource));
      }
    }));
  };

  return Resource;
}(Component);

export { Resource };
Resource.propTypes = {
  context: PropTypes.oneOf(['route', 'registration']).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  list: componentPropType,
  create: componentPropType,
  edit: componentPropType,
  show: componentPropType,
  icon: componentPropType,
  options: PropTypes.object,
  registerResource: PropTypes.func.isRequired,
  unregisterResource: PropTypes.func.isRequired
};
Resource.defaultProps = {
  context: 'route',
  options: {}
};
export default connect(null, {
  registerResource: registerResource,
  unregisterResource: unregisterResource
})(Resource);