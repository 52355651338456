/**
 * Created by bioz on 4/5/2017.
 */

import React from 'react';
import {
    DateInput,
    DeleteButton,
    List,
    Filter,
    Datagrid,
    DateField,
    SelectInput,
    TextField,
    SearchInput,
    Responsive,
    ReferenceField
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/ViewHeadline';

import {
    StatusEnum,
} from '../biUtils/constant';
import { ArrayMapField } from '../biUtils/BiField';
export const FittingMediaIcon = Icon;

const filterStyles = {
  status: { width: 150 },
};
const FittingMediaFilter = withStyles(filterStyles)(
  ({ classes, ...props })  => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput
            source="status"
            label="Status"
            choices={StatusEnum}
            optionText="name"
            optionValue="id"
            className={classes.status}
        />
        <DateInput
            source="createdAt_gte"
            label="Created After"
        />
        <DateInput
            source="createdAt_lte"
            label="Created Before"
        />
        <DateInput
            source="expiredAt_gte"
            label="Expired After"
        />
        <DateInput
            source="expiredAt_lte"
            label="Expired Before"
        />
    </Filter>
));

const listStyles = {
  shortText: {
    maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  longText: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
export const FittingMediaList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        title="All Fitting Media"
        {...props}
        filters={<FittingMediaFilter />}
        perPage={25}
        sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Responsive
        medium={
        <Datagrid>
            <DateField source="createdAt" showTime label="Create" />
            <DateField source="expiredAt" showTime label="Expire" />
            <ArrayMapField
                source="status"
                label="Status"
                arrayData={StatusEnum}
            />
            <TextField source="code" label="Code" />
            <TextField source="device" label="Device"/>
            <TextField source="totalFile" label="Total File"/>
            <ReferenceField source="creator" reference="auth/accounts" label="Creator">
            <TextField source="displayName" cellClassName={classes.shortText}/>
            </ReferenceField>
            <DeleteButton />
        </Datagrid>
        }
      />
    </List>
));
