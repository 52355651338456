import { CRUD_GET_ONE_SUCCESS, CRUD_GET_LIST_SUCCESS, CRUD_DELETE_OPTIMISTIC, CRUD_DELETE_MANY_OPTIMISTIC } from '../../../../actions/dataActions';
export default (function (previousState, _a) {
  if (previousState === void 0) {
    previousState = 0;
  }

  var type = _a.type,
      payload = _a.payload;

  if (type === CRUD_GET_ONE_SUCCESS) {
    return previousState == 0 ? 1 : previousState;
  }

  if (type === CRUD_GET_LIST_SUCCESS) {
    return payload.total;
  }

  if (type === CRUD_DELETE_OPTIMISTIC) {
    return previousState - 1;
  }

  if (type === CRUD_DELETE_MANY_OPTIMISTIC) {
    return previousState - payload.ids.length;
  }

  return previousState;
});