var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import pickBy from 'lodash/pickBy';
var defaultCacheDuration = 10 * 60 * 1000; // ten minutes

export default (function (newRecordIds, oldRecordFetchedAt, now, cacheDuration) {
  if (newRecordIds === void 0) {
    newRecordIds = [];
  }

  if (oldRecordFetchedAt === void 0) {
    oldRecordFetchedAt = {};
  }

  if (now === void 0) {
    now = new Date();
  }

  if (cacheDuration === void 0) {
    cacheDuration = defaultCacheDuration;
  } // prepare new records and timestamp them


  var newFetchedAt = newRecordIds.reduce(function (prev, recordId) {
    var _a;

    return __assign({}, prev, (_a = {}, _a[recordId] = now, _a));
  }, {}); // remove outdated entry

  var latestValidDate = new Date();
  latestValidDate.setTime(latestValidDate.getTime() - cacheDuration);
  var stillValidFetchedAt = pickBy(oldRecordFetchedAt, function (date) {
    return date > latestValidDate;
  });
  return __assign({}, stillValidFetchedAt, newFetchedAt);
});