var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import { linkToRecord } from 'ra-core';
import DatagridCell from './DatagridCell';

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var basePath = _a.basePath,
      children = _a.children,
      classes = _a.classes,
      className = _a.className,
      rowClick = _a.rowClick,
      id = _a.id,
      isLoading = _a.isLoading,
      onToggleItem = _a.onToggleItem,
      push = _a.push,
      record = _a.record,
      resource = _a.resource,
      selected = _a.selected,
      style = _a.style,
      styles = _a.styles,
      rest = __rest(_a, ["basePath", "children", "classes", "className", "rowClick", "id", "isLoading", "onToggleItem", "push", "record", "resource", "selected", "style", "styles"]);

  return rest;
};

var DatagridRow =
/** @class */
function (_super) {
  __extends(DatagridRow, _super);

  function DatagridRow() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleToggle = function (event) {
      _this.props.onToggleItem(_this.props.id);

      event.stopPropagation();
    };

    _this.handleClick = function () {
      var _a = _this.props,
          basePath = _a.basePath,
          rowClick = _a.rowClick,
          id = _a.id,
          push = _a.push;
      if (!rowClick) return;

      if (rowClick === 'edit') {
        push(linkToRecord(basePath, id));
      }

      if (rowClick === 'show') {
        push(linkToRecord(basePath, id, 'show'));
      }

      if (typeof rowClick === 'function') {
        push(rowClick(id, basePath));
      }
    };

    return _this;
  }

  DatagridRow.prototype.render = function () {
    var _a = this.props,
        basePath = _a.basePath,
        children = _a.children,
        classes = _a.classes,
        className = _a.className,
        hasBulkActions = _a.hasBulkActions,
        hover = _a.hover,
        id = _a.id,
        record = _a.record,
        resource = _a.resource,
        selected = _a.selected,
        style = _a.style,
        styles = _a.styles,
        rest = __rest(_a, ["basePath", "children", "classes", "className", "hasBulkActions", "hover", "id", "record", "resource", "selected", "style", "styles"]);

    return React.createElement(TableRow, __assign({
      className: className,
      key: id,
      style: style,
      hover: hover,
      onClick: this.handleClick
    }, sanitizeRestProps(rest)), hasBulkActions && React.createElement(TableCell, {
      padding: "none"
    }, React.createElement(Checkbox, {
      color: "primary",
      className: "select-item " + classes.checkbox,
      checked: selected,
      onClick: this.handleToggle
    })), React.Children.map(children, function (field, index) {
      return field ? React.createElement(DatagridCell, __assign({
        key: id + "-" + (field.props.source || index),
        className: classnames("column-" + field.props.source, classes.rowCell),
        record: record,
        id: id
      }, {
        field: field,
        basePath: basePath,
        resource: resource
      })) : null;
    }));
  };

  return DatagridRow;
}(Component);

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object
};
DatagridRow.defaultProps = {
  hasBulkActions: false,
  hover: true,
  record: {},
  selected: false
}; // wat? TypeScript looses the displayName if we don't set it explicitly

DatagridRow.displayName = 'DatagridRow';
export default connect(null, {
  push: push
})(DatagridRow);