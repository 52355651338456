/**
 * Created by bioz on 4/17/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';

const styles = {
    main: { display: 'flex', flexWrap: 'wrap' },
};

function getCamArray(arrayData, defValue) {
    let result = [];
    if (arrayData !== null && arrayData !== undefined && arrayData.length > 0) {
        arrayData.map(segment => (
            result.push({id: segment.deviceId, name: segment.label})
        ));
    } else {
        if (defValue !== null && defValue !== undefined) {
            result.push({id: defValue, name: defValue});
        }
    }
    return result;
}

export const CameraSelector = ({ label, source, record = {} }) => (
    <SelectInput
        label={label}
        source={source}
        choices={getCamArray(record['webcams'], record[{source}])}
        optionText="name"
        optionValue="id"
        allowEmpty="true"
        style={styles.main}
    />
);

CameraSelector.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
