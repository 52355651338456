/**
 * Created by bioz on 4/5/2017.
 */

import React from 'react';
import {
    DateInput,
    ReferenceInput,
    LongTextInput,
    NumberInput,
    List,
    Edit,
    Create,
    Filter,
    DeleteButton,
    Datagrid,
    TextInput,
    DateField,
    EditButton,
    SelectInput,
    TextField,
    Responsive,
    SearchInput,
    TabbedForm,
    ReferenceField,
    FormTab,
    BooleanField,
    BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Devices';

import { StatusEnum, ResolutionEnum, RotateEnum } from "../biUtils/constant";
import { ArrayMapField } from '../biUtils/BiField';
import {CameraSelector} from "./cameraSelector";
import {SensorSelector} from "./sensorSelector";

export const DeviceIcon = Icon;

const filterStyles = {
    status: { width: 150 },
};
const DeviceFilter = withStyles(filterStyles)(
    ({ classes, ...props })  => (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput
                source="status"
                label="Status"
                choices={StatusEnum}
                optionText="name"
                optionValue="id"
                className={classes.status}
            />
            <DateInput
                source="createdAt_gte"
                label="Created After"
            />
            <DateInput
                source="createdAt_lte"
                label="Created Before"
            />
            <DateInput
                source="updatedAt_gte"
                label="Updated After"
            />
            <DateInput
                source="updatedAt_lte"
                label="Updated Before"
            />
        </Filter>
    )
);

const DeviceEditTitle = ({ record }) => {
    return <span>Device {record ? `"${record.id}"` : ''}</span>;
};

const listStyles = {
  shortText: {
    maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  longText: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
export const DeviceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        title="All Devices"
        {...props}
        filters={<DeviceFilter />}
        perPage={15}
        sort={{ field: 'name', order: 'DESC' }}
    >
          <Responsive
                medium={
                <Datagrid>
                    <DateField source="updatedAt" label="Update" />
                    <ReferenceField source="creator" reference="auth/accounts" label="Creator">
                      <TextField source="displayName" cellClassName={classes.shortText}/>
                    </ReferenceField>
                    <ArrayMapField
                        source="status"
                        label="Status"
                        arrayData={StatusEnum}
                    />
                    <TextField source="id" label="ID"/>
                    <TextField source="code" label="Code"
                               cellClassName={classes.shortText}
                    />
                    <BooleanField source="isFullscreen" label="Full" />
                    <TextField source="companyCode" label="Company"
                               cellClassName={classes.shortText}
                    />
                    <TextField source="siteCode" label="Site"
                               cellClassName={classes.shortText}
                    />
                    <BooleanField source="isAlive" label="Alive" />
                    <BooleanField source="isDebugToolOpened" label="Debug" />
                    <BooleanField source="isIrEnable" label="IR" />
                    <BooleanField source="isMovementEnable" label="Motion" />
                    <TextField source="countdownTime" label="Delay" />
                    <ArrayMapField
                        source="resolution"
                        label="Resolution"
                        arrayData={ResolutionEnum}
                    />
                    <TextField source="rotate" label="rotate" />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
                }
          />
    </List>
));

const editStyle = {
    createdDate: { display: 'inline-block', marginRight: '1em' },
    updatedDate: { display: 'inline-block' },
    isFullscreen: { display: 'inline-block', marginRight: '1em', marginTop: '1em' },
    isDevEnabled: { display: 'inline-block', marginRight: '1em', marginTop: '1em' },
    isModeChangeEnabled: { display: 'inline-block', marginTop: '1em' },
};

export const DeviceEdit = withStyles(editStyle)(({ classes, ...props }) => (
    <Edit {...props} title={<DeviceEditTitle />}>
        <TabbedForm>
            <FormTab label="Base">
                <TextInput
                    source="name"
                    label="Name"
                    validation={{ required: true, minLength: 4, maxLength: 128 }}
                />
                <SelectInput
                    source="status"
                    label="Status"
                    choices={StatusEnum}
                    optionText="name"
                    optionValue="id"
                    defaultValue={StatusEnum[0].id}
                />
                <TextInput
                    source="companyCode"
                    label="Company"
                    validation={{ required: true, minLength: 4, maxLength: 128 }}
                />
                <TextInput
                    source="siteCode"
                    label="Site"
                    validation={{ required: true, minLength: 4, maxLength: 128 }}
                />
                <TextField source="adminPassword" label="Password" />
                <TextField source="socketIO" label="SocketId" />
            </FormTab>
            <FormTab label="Camera">
                <SelectInput
                    source="resolution"
                    label="Resolution"
                    choices={ResolutionEnum}
                    optionText="name"
                    optionValue="id"
                    defaultValue={ResolutionEnum[0].id}
                />
                <SelectInput
                    source="rotate"
                    label="Rotate"
                    choices={RotateEnum}
                    optionText="name"
                    optionValue="id"
                    defaultValue={RotateEnum[0].id}
                />
                <CameraSelector source="camera" label="Camera" />
            </FormTab>
            <FormTab label="Setting">
                <NumberInput
                    source="countdownTime"
                    label="Delay"
                    defaultValue={0}
                    validation={{ min: 0 }}
                />
                <BooleanInput source="isFullscreen" label="Full Screen"/>
                <BooleanInput source="isMovementEnable" label="Movement"/>
                <BooleanInput source="isIrEnable" label="IR"/>
                <BooleanInput source="isNeedOpenHelp" label="Open Help"/>
                <BooleanInput source="isDebugToolOpened" label="Enable Debug"/>
                <BooleanInput source="isCamInfoDisplayed" label="Display Cam Info"/>
                <SensorSelector source="comName" label="Com" />
            </FormTab>
            <FormTab label="Timer">
                <NumberInput
                    source="autoShutdownTime"
                    label="Shutdown Time"
                    defaultValue={0}
                    validation={{ min: 0, max: 24 }}
                />
                <NumberInput
                    source="autoShutdownTimeMode"
                    label="Time Mode"
                    defaultValue={0}
                    validation={{ min: 0, max: 1 }}
                />
                <NumberInput
                    source="productModeTime"
                    label="Product Display Time (s)"
                    defaultValue={0}
                    validation={{ min: 0}}
                />
                <NumberInput
                    source="previewTime"
                    label="Advertisement Preview Time (s)"
                    defaultValue={0}
                    validation={{ min: 0}}
                />
            </FormTab>
            <FormTab label="Description">
                <DateField source="createdAt" label="Create" showTime formClassName={classes.createdDate}/>
                <DateField source="updatedAt" label="Update" showTime formClassName={classes.updatedDate}/>
                <LongTextInput
                    source="desc"
                    label="Desc"
                    defaultValue="(~_^)"
                    validation={{ required: true, minLength: 0, maxLength: 256 }}
                />
            </FormTab>
        </TabbedForm>
    </Edit>
));
