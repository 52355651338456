var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { translate, crudDelete, startUndoable } from 'ra-core';
import Button from './Button';

var styles = function styles(theme) {
  return {
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  };
};

var DeleteButton =
/** @class */
function (_super) {
  __extends(DeleteButton, _super);

  function DeleteButton() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleDelete = function (event) {
      event.preventDefault();
      var _a = _this.props,
          dispatchCrudDelete = _a.dispatchCrudDelete,
          startUndoable = _a.startUndoable,
          resource = _a.resource,
          record = _a.record,
          basePath = _a.basePath,
          redirect = _a.redirect,
          undoable = _a.undoable;

      if (undoable) {
        startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
      } else {
        dispatchCrudDelete(resource, record.id, record, basePath, redirect);
      }
    };

    return _this;
  }

  DeleteButton.prototype.render = function () {
    var _a = this.props,
        _b = _a.label,
        label = _b === void 0 ? 'ra.action.delete' : _b,
        _c = _a.classes,
        classes = _c === void 0 ? {} : _c,
        className = _a.className;
    return React.createElement(Button, {
      onClick: this.handleDelete,
      label: label,
      className: classnames('ra-delete-button', classes.deleteButton, className),
      key: "button"
    }, React.createElement(ActionDelete, null));
  };

  return DeleteButton;
}(Component);

DeleteButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool
};
DeleteButton.defaultProps = {
  redirect: 'list',
  undoable: true
};
export default compose(connect(null, {
  startUndoable: startUndoable,
  dispatchCrudDelete: crudDelete
}), translate, withStyles(styles))(DeleteButton);