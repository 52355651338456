/**
 * Created by bioz on 4/17/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import {SelectInput} from 'react-admin';

const styles = {
    main: { display: 'flex', flexWrap: 'wrap' },
    chip: { margin: 4 },
};

function getNameById(id, arrayData) {
    let idx = arrayData.findIndex(obj => obj.id === id);
    if (idx != null && idx >= 0) {
        return arrayData[idx].name;
    }
    return '';
}

export const CheckExist = function(objectArray, elementId) {
    let iResult = 0;
    if (objectArray && objectArray.length > 0) {
        for (let i = 0; i < objectArray.length; i++) {
            let obj = objectArray[i];
            if (obj.id === elementId) {
                iResult = i + 1;
                break;
            }
        }
    }
    return iResult;
};

////////////////////// Custom Component ///////////////////////////
export const StorageFromByteToGBField = ({ source, record = {} }) => (
    <span>
        {record[source]
            ? (record[source] / (1024 * 1024 * 1024)).toFixed(2)
            : 0}
    </span>
);

StorageFromByteToGBField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export const ArrayMapField = ({ source, record = {}, arrayData }) => (
    <span>{getNameById(record[source], arrayData)}</span>
);

ArrayMapField.propTypes = {
    arrayData: PropTypes.array,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export const SegmentsField = ({ source, record = {} }) => (
    <span style={styles.main}>
        {record[source].map(segment => (
            <Chip key={segment} style={styles.chip}>
                {segment}
            </Chip>
        ))}
    </span>
);

SegmentsField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};