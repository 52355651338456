var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import DatagridHeaderCell from './DatagridHeaderCell';
import DatagridBody from './DatagridBody';
var styles = {
  table: {
    tableLayout: 'auto'
  },
  tbody: {
    height: 'inherit'
  },
  headerCell: {
    padding: '0 12px'
  },
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer'
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {
    padding: '0 12px',
    '&:last-child': {
      padding: '0 12px'
    }
  }
};
/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - styles
 *  - rowStyle
 *  - options (passed as props to <Table>)
 *  - headerOptions (passed as props to mui <TableHead>)
 *  - bodyOptions (passed as props to mui <TableBody>)
 *  - rowOptions (passed as props to mui <TableRow>)
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 */

var Datagrid =
/** @class */
function (_super) {
  __extends(Datagrid, _super);

  function Datagrid() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.updateSort = function (event) {
      event.stopPropagation();

      _this.props.setSort(event.currentTarget.dataset.sort);
    };

    _this.handleSelectAll = function (event) {
      var _a = _this.props,
          onSelect = _a.onSelect,
          ids = _a.ids,
          selectedIds = _a.selectedIds;

      if (event.target.checked) {
        onSelect(ids.reduce(function (idList, id) {
          return idList.includes(id) ? idList : idList.concat(id);
        }, selectedIds));
      } else {
        onSelect([]);
      }
    };

    return _this;
  }

  Datagrid.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        basePath = _a.basePath,
        data = _a.data,
        children = _a.children,
        classes = _a.classes,
        className = _a.className,
        currentSort = _a.currentSort,
        hasBulkActions = _a.hasBulkActions,
        hover = _a.hover,
        ids = _a.ids,
        isLoading = _a.isLoading,
        resource = _a.resource,
        rowStyle = _a.rowStyle,
        selectedIds = _a.selectedIds,
        setSort = _a.setSort,
        onSelect = _a.onSelect,
        onToggleItem = _a.onToggleItem,
        rowClick = _a.rowClick,
        total = _a.total,
        version = _a.version,
        rest = __rest(_a, ["basePath", "data", "children", "classes", "className", "currentSort", "hasBulkActions", "hover", "ids", "isLoading", "resource", "rowStyle", "selectedIds", "setSort", "onSelect", "onToggleItem", "rowClick", "total", "version"]);

    if (!isLoading && (ids.length === 0 || total === 0)) {
      return null;
    }

    return React.createElement(Table, __assign({
      className: classnames(classes.table, className)
    }, sanitizeListRestProps(rest)), React.createElement(TableHead, null, React.createElement(TableRow, {
      className: classes.row
    }, hasBulkActions && React.createElement(TableCell, {
      padding: "none"
    }, React.createElement(Checkbox, {
      className: "select-all",
      color: "primary",
      checked: selectedIds.length > 0 && ids.length > 0 && !ids.find(function (it) {
        return selectedIds.indexOf(it) === -1;
      }),
      onChange: this.handleSelectAll
    })), React.Children.map(children, function (field, index) {
      return field ? React.createElement(DatagridHeaderCell, {
        className: classes.headerCell,
        currentSort: currentSort,
        field: field,
        isSorting: field.props.source === currentSort.field,
        key: field.props.source || index,
        resource: resource,
        updateSort: _this.updateSort
      }) : null;
    }))), React.createElement(DatagridBody, {
      basePath: basePath,
      classes: classes,
      rowClick: rowClick,
      data: data,
      hasBulkActions: hasBulkActions,
      hover: hover,
      ids: ids,
      isLoading: isLoading,
      onToggleItem: onToggleItem,
      resource: resource,
      rowStyle: rowStyle,
      selectedIds: selectedIds,
      version: version
    }, children));
  };

  return Datagrid;
}(Component);

Datagrid.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    sort: PropTypes.string,
    order: PropTypes.string
  }).isRequired,
  data: PropTypes.object.isRequired,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number
};
Datagrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: []
};
export default withStyles(styles)(Datagrid);