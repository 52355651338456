import { CRUD_CHANGE_LIST_PARAMS } from '../../../../actions/listActions';
var defaultState = {
  sort: null,
  order: null,
  page: 1,
  perPage: null,
  filter: {}
};
export default (function (previousState, _a) {
  if (previousState === void 0) {
    previousState = defaultState;
  }

  var type = _a.type,
      payload = _a.payload;

  switch (type) {
    case CRUD_CHANGE_LIST_PARAMS:
      return payload;

    default:
      return previousState;
  }
});