var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { USER_LOGOUT } from './actions/authActions';
import createAppReducer from './reducer';
import { adminSaga } from './sideEffect';
import { defaultI18nProvider } from './i18n';
import formMiddleware from './form/formMiddleware';
export default (function (_a) {
  var authProvider = _a.authProvider,
      _b = _a.customReducers,
      customReducers = _b === void 0 ? {} : _b,
      _c = _a.customSagas,
      customSagas = _c === void 0 ? [] : _c,
      dataProvider = _a.dataProvider,
      _d = _a.i18nProvider,
      i18nProvider = _d === void 0 ? defaultI18nProvider : _d,
      history = _a.history,
      initialState = _a.initialState,
      _e = _a.locale,
      locale = _e === void 0 ? 'en' : _e;
  var messages = i18nProvider(locale);
  var appReducer = createAppReducer(customReducers, locale, messages);

  var resettableAppReducer = function resettableAppReducer(state, action) {
    return appReducer(action.type !== USER_LOGOUT ? state : undefined, action);
  };

  var saga = function rootSaga() {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , all([adminSaga(dataProvider, authProvider, i18nProvider)].concat(customSagas).map(fork))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  };

  var sagaMiddleware = createSagaMiddleware();
  var store = createStore(resettableAppReducer, initialState, compose(applyMiddleware(sagaMiddleware, formMiddleware, routerMiddleware(history)), typeof window !== 'undefined' && window.devToolsExtension ? window.devToolsExtension() : function (f) {
    return f;
  }));
  sagaMiddleware.run(saga);
  return store;
});