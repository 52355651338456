var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

var styles = function styles(theme) {
  return {
    root: {
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'flex-start'
    },
    active: {
      color: theme.palette.text.primary
    },
    icon: {
      paddingRight: '1.2em'
    }
  };
};

var MenuItemLink =
/** @class */
function (_super) {
  __extends(MenuItemLink, _super);

  function MenuItemLink() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleMenuTap = function () {
      _this.props.onClick && _this.props.onClick();
    };

    return _this;
  }

  MenuItemLink.prototype.render = function () {
    var _a = this.props,
        classes = _a.classes,
        className = _a.className,
        primaryText = _a.primaryText,
        leftIcon = _a.leftIcon,
        staticContext = _a.staticContext,
        props = __rest(_a, ["classes", "className", "primaryText", "leftIcon", "staticContext"]);

    return React.createElement(MenuItem, __assign({
      className: classnames(classes.root, className),
      activeClassName: classes.active,
      component: NavLink
    }, props, {
      onClick: this.handleMenuTap
    }), leftIcon && React.createElement("span", {
      className: classes.icon
    }, cloneElement(leftIcon, {
      titleAccess: primaryText
    })), primaryText);
  };

  MenuItemLink.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    staticContext: PropTypes.object,
    to: PropTypes.string.isRequired
  };
  return MenuItemLink;
}(Component);

export { MenuItemLink };
export default withStyles(styles)(MenuItemLink);