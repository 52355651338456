/**
 * Created by bioz on 4/17/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {SelectInput} from 'react-admin';

const styles = {
    main: { display: 'flex', flexWrap: 'wrap' },
};

function getSensorArray(arrayData, defValue) {
    let result = [];
    if(arrayData!==null && arrayData!== undefined && arrayData.length > 0){
        arrayData.map(segment => (
            result.push({id: segment, name: segment})
        ));
    }else{
        if(defValue !== null && defValue !== undefined){
            result.push({id:defValue, name:defValue});
        }
    }
    return result;
}

export const SensorSelector = ({ label, source, record = {} }) => (
    <SelectInput
        label={label}
        source={source}
        choices={getSensorArray(record['sensors'], record[{source}])}
        optionText="name"
        optionValue="id"
        allowEmpty="true"
        style={styles.main}
    />
);

SensorSelector.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
