import { DEFAULT_LOCALE } from '../../i18n/index';
import { CHANGE_LOCALE_SUCCESS } from '../../actions/localeActions';
export default (function (initialLocale) {
  if (initialLocale === void 0) {
    initialLocale = DEFAULT_LOCALE;
  }

  return function (previousLocale, _a) {
    if (previousLocale === void 0) {
      previousLocale = initialLocale;
    }

    var type = _a.type,
        payload = _a.payload;

    switch (type) {
      case CHANGE_LOCALE_SUCCESS:
        return payload.locale;

      default:
        return previousLocale;
    }
  };
});