var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import { FETCH_END } from '../../../actions/fetchActions';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE } from '../../../dataFetchActions';
import { CRUD_DELETE_OPTIMISTIC, CRUD_DELETE_MANY_OPTIMISTIC, CRUD_UPDATE_OPTIMISTIC, CRUD_UPDATE_MANY_OPTIMISTIC } from '../../../actions/dataActions';
import getFetchedAt from '../../../util/getFetchedAt';
/**
 * Add new records to the pool, and remove outdated ones.
 *
 * This is the equivalent of a stale-while-revalidate caching strategy:
 * The cached data is displayed before fetching, and stale data is removed
 * only once fresh data is fetched.
 */

export var addRecordsFactory = function addRecordsFactory(getFetchedAt) {
  return function (newRecords, oldRecords) {
    if (newRecords === void 0) {
      newRecords = [];
    }

    var newFetchedAt = getFetchedAt(newRecords.map(function (_a) {
      var id = _a.id;
      return id;
    }), oldRecords.fetchedAt);
    var newRecordsById = newRecords.reduce(function (acc, record) {
      var _a;

      return __assign({}, acc, (_a = {}, _a[record.id] = record, _a));
    }, {});
    var records = Object.keys(newFetchedAt).reduce(function (acc, id) {
      var _a;

      return __assign({}, acc, (_a = {}, _a[id] = newRecordsById[id] || oldRecords[id], _a));
    }, {});
    Object.defineProperty(records, 'fetchedAt', {
      value: newFetchedAt
    }); // non enumerable by default

    return records;
  };
};
var addRecords = addRecordsFactory(getFetchedAt);
var initialState = {};
Object.defineProperty(initialState, 'fetchedAt', {
  value: {}
}); // non enumerable by default

export default (function (previousState, _a) {
  if (previousState === void 0) {
    previousState = initialState;
  }

  var type = _a.type,
      payload = _a.payload,
      meta = _a.meta;

  if (type === CRUD_UPDATE_OPTIMISTIC) {
    var updatedRecord = __assign({}, previousState[payload.id], payload.data);

    return addRecords([updatedRecord], previousState);
  }

  if (type === CRUD_UPDATE_MANY_OPTIMISTIC) {
    var updatedRecords = payload.ids.reduce(function (records, id) {
      return records.concat(previousState[id]);
    }, []).map(function (record) {
      return __assign({}, record, payload.data);
    });
    return addRecords(updatedRecords, previousState);
  }

  if (type === CRUD_DELETE_OPTIMISTIC) {
    var _b = payload.id,
        removed = previousState[_b],
        newState = __rest(previousState, [typeof _b === "symbol" ? _b : _b + ""]);

    Object.defineProperty(newState, 'fetchedAt', {
      value: previousState.fetchedAt
    });
    return newState;
  }

  if (type === CRUD_DELETE_MANY_OPTIMISTIC) {
    var newState = Object.entries(previousState).filter(function (_a) {
      var key = _a[0];
      return !payload.ids.includes(key);
    }).reduce(function (obj, _a) {
      var key = _a[0],
          val = _a[1];

      var _b;

      return __assign({}, obj, (_b = {}, _b[key] = val, _b));
    }, {});
    Object.defineProperty(newState, 'fetchedAt', {
      value: previousState.fetchedAt
    });
    return newState;
  }

  if (!meta || !meta.fetchResponse || meta.fetchStatus !== FETCH_END) {
    return previousState;
  }

  switch (meta.fetchResponse) {
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE:
      return addRecords(payload.data, previousState);

    case GET_ONE:
    case UPDATE:
    case CREATE:
      return addRecords([payload.data], previousState);

    default:
      return previousState;
  }
});
export var getRecord = function getRecord(state, id) {
  return state[id];
};