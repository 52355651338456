import { CHANGE_LOCALE_SUCCESS } from '../../actions/index';
export default (function (defaultMessages) {
  return function (previousState, _a) {
    if (previousState === void 0) {
      previousState = defaultMessages;
    }

    var type = _a.type,
        payload = _a.payload;

    switch (type) {
      case CHANGE_LOCALE_SUCCESS:
        return payload.messages;

      default:
        return previousState;
    }
  };
});