/**
 * Created by bioz on 4/5/2017.
 */

import React from 'react';
import {
    DateInput,
    Create,
    Edit,
    List,
    Filter,
    Datagrid,
    TextInput,
    DeleteButton,
    NumberInput,
    SelectInput,
    EmailField,
    DateField,
    EditButton,
    SimpleForm,
    TextField,
    Responsive,
    BooleanField,
    SearchInput,
    BooleanInput,
} from 'react-admin';
import { StatusEnum, UserRightEnum } from '../biUtils/constant';
import { ArrayMapField } from '../biUtils/BiField';
import Icon from '@material-ui/icons/Person';
import withStyles from "@material-ui/core/styles/withStyles";
export const AccountIcon = Icon;

const filterStyles = {
  status: { width: 150 },
};
const UserFilter = withStyles(filterStyles)(
    ({ classes, ...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput
            source="status"
            label="Status"
            choices={StatusEnum}
            optionText="name"
            optionValue="id"
            className={classes.status}
        />
        <SelectInput
            source="type"
            label="Type"
            choices={UserRightEnum}
            optionText="name"
            optionValue="id"
        />
        <BooleanInput
            source="isAlive"
            label="Live"
        />
        <DateInput
            source="createdAt_gte"
            label="Created After"
        />
        <DateInput
            source="createdAt_lte"
            label="Created Before"
        />
        <DateInput
            source="updatedAt_gte"
            label="Updated After"
        />
        <DateInput
            source="updatedAt_lte"
            label="Updated Before"
        />
    </Filter>
));

const AccountEditTitle = ({ record }) => {
    return <span>Account {record ? `"${record.displayName}"` : ''}</span>;
};

const listStyles = {
  shortText: {
    maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const AccountList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        title="All Users"
        filters={<UserFilter />}
        perPage={50}
        sort={{ field: 'username', order: 'DESC' }}
    >
        <Responsive
        medium={
            <Datagrid>
                <DateField source="updatedAt" label="Updated" />
                <DateField source="createdAt" label="Created" />
                <TextField source="username" label="User Name" />
                <TextField source="displayName" label="Display" />
                <ArrayMapField
                    source="type"
                    label="Role"
                    arrayData={UserRightEnum}
                />
                <EmailField source="email" label="Email" />
                <ArrayMapField
                    source="status"
                    label="Status"
                    arrayData={StatusEnum}
                />
                <BooleanField source="isSystem" label="Fixed" />
                <TextField source="creator" label="Creator"
                     cellClassName={classes.shortText}
                />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        }
      />
    </List>
));

const editStyle = {
  detail: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '2em',
    minWidth: '8em',
  },
};
export const AccountEdit = withStyles(editStyle)(({ classes, ...props }) => (
    <Edit {...props} title={<AccountEditTitle />} >
        <SimpleForm>
            <TextInput
                source="username"
                label="User Name"
                validation={{ required: true, minLength: 6, maxLength: 32 }}
            />
            <TextInput
                source="password"
                label="Password"
                type="password"
                value=""
                validation={{ required: true, minLength: 4, maxLength: 32 }}
            />
            <TextInput
                source="displayName"
                label="Display"
                validation={{ required: true, minLength: 6, maxLength: 32 }}
            />
            <TextInput
                source="email"
                label="Email"
                type="email"
                validation={{ email: true, required: true }}
            />
            <SelectInput
                source="type"
                label="Right"
                choices={UserRightEnum}
                optionText="name"
                optionValue="id"
            />
            <SelectInput
                source="status"
                label="Status"
                choices={StatusEnum}
                optionText="name"
                optionValue="id"
            />
            <DateField source="createdAt" label="Create" formClassName={classes.detail}/>
            <DateField source="updatedAt" label="Update" formClassName={classes.detail}/>
        </SimpleForm>
    </Edit>
));

export const AccountCreate = props => (
  <Create title="Create User" {...props}>
    <SimpleForm>
      <TextInput
        source="username"
        label="User Name"
        validation={{ required: true, minLength: 4, maxLength: 64 }}
      />
      <TextInput
        source="displayName"
        label="Display"
        validation={{ required: true, minLength: 4, maxLength: 64 }}
      />
      <TextInput
        source="password"
        label="Password"
        type="password"
        defaultValue="NONE"
        validation={{ required: true, minLength: 4, maxLength: 64 }}
      />
      <TextInput
        source="email"
        label="Email"
        type="email"
        validation={{ email: true, required: true }}
      />
      <SelectInput
        source="type"
        label="Right"
        choices={UserRightEnum}
        optionText="name"
        optionValue="id"
        defaultValue={UserRightEnum[0].id}
      />
      <SelectInput
        source="status"
        label="Status"
        choices={StatusEnum}
        optionText="name"
        optionValue="id"
        defaultValue={StatusEnum[0].id}
      />
    </SimpleForm>
  </Create>
);
